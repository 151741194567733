<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Rate Card Name"
              label-for="v-name"
            >
              <b-form-input
                id="v-name"
                v-model="filters['card_name']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Ref No"
              label-for="v-ref-no"
            >
              <b-form-input
                id="v-ref-no"
                v-model="filters['ref_no']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <!-- input -->
            <b-row>
              <b-col
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mt-1 mb-md-0"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-can="'add-walking-rate-card'"
                  class="custom-button-color"
                  :to="{ name: 'add-rate-card', params: { rateCardType: 'walking-customer'} }"
                >
                  <feather-icon icon="PlusIcon" />
                  Add New
                </b-button>
              </b-col>

            </b-row>
          </div>

          <!-- table -->
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              :fields="fields"
              :items="rows"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              hover
              responsive
              striped
              show-empty
              sticky-header="100vh"
              class="hide-vertical-scroll"
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>

              <template #cell(staff)="data">
                <div>
                  {{ data.value ? data.value.first_name + ' ' + data.value.last_name : '---' }}
                </div>
                <div>
                  {{ data.value ? data.value.email : '' }}
                </div>
              </template>

              <template #cell(is_active_walking)="data">
                <b-badge :variant="data.value ? 'light-success':'light-danger'">
                  {{ data.value ? 'Yes' : 'No' }}
                </b-badge>
              </template>
              <template #cell(action)="data">
                {{ data.value }}
                <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
                  variant="link"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      class="text-body align-middle mr-25"
                      icon="MoreVerticalIcon"
                      size="12"
                    />
                  </template>
                  <b-dropdown-item
                    v-can="'assign-walking-rate-card'"
                    @click="onClickSetDefault(data.item.id)"
                  >
                    <feather-icon icon="ToolIcon" />
                    Set as Default
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'show-walking-rate-card'"
                  >
                    <router-link
                      style="text-decoration: none; color: inherit;"
                      :to="{ name: 'view-rate-card', params: { rateCardType: 'walking-customer', id: data.item.id } }"
                    >
                      <feather-icon icon="EyeIcon" />
                      View Rate Card
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'show-walking-rate-card'"
                    style="display:none"
                    :to="{ name: 'update-rate-card', params: { rateCardType: 'walking-customer', id: data.item.id} }"
                  >
                    <feather-icon icon="Edit2Icon" />
                    Update Rate Card
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'show-walking-rate-card'"
                    :to="{ name: 'clone-rate-card', params: { rateCardType: 'walking-customer', id: data.item.id} }"
                  >
                    <feather-icon icon="LogInIcon" />
                    Clone Rate Card
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'show-walking-rate-card'"
                    @click="deleteRatingCardConfirmationBox(data.item.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                    Delete Rate Card
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  cols="12"
                  md="3"
                >
                  <label class="width-75">Per page</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-1 width-100 mr-2"
                  />
                </b-col>
                <!-- Pagination -->
                <b-col
                  class="d-flex align-items-center justify-content-sm-end"
                  cols="12"
                  md="9"
                >
                  <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
import rateCardRepository from '@/repository/rateCardRepository'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const RateCardRepository = RepositoryFactory.get('ratecard')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCardActions,
    BPagination,
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      filters: {},
      total: 0,
      meta: {},
      dataLoading: false,
      // Start BS Table
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      form: {},
      showUpdateModal: false,
      modelLoading: false,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // End BS Table
      pageLength: 20,
      dir: false,
      fields: [
        {
          key: 'card_name',
          label: 'Rate Card Name',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Created Date',
          sortable: true,
        },
        {
          key: 'staff',
          label: 'Modified By',
          sortable: true,
        },
        {
          key: 'is_active_walking',
          label: 'Is Active',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },
  watch: {
    options: {
      handler() {
        this.getWalkingCustomerRateCardList()
      },
    },
    deep: true,
    page() {
      this.getWalkingCustomerRateCardList()
    },
    perPage() {
      this.getWalkingCustomerRateCardList()
    },
  },
  mounted() {
    this.getWalkingCustomerRateCardList()
  },
  methods: {
    async getWalkingCustomerRateCardList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await RateCardRepository.getWalkingCustomerRateCardList(this.filterQuery, this.page, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async onClickSetDefault(id) {
      try {
        const res = await RateCardRepository.setDefaultWalkingCustomerRateCard(id)
        if (res.status === 200) {
          this.showSuccessMessage('Rate card updated as default successfully')
          this.getWalkingCustomerRateCardList()
        } else {
          this.showErrorMessage("Couldn't update as default rate card")
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    applyFilters() {
      this.getWalkingCustomerRateCardList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.getWalkingCustomerRateCardList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.getWalkingCustomerRateCardList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.getWalkingCustomerRateCardList()
    },
    async deleteRatingCardConfirmationBox(id) {
      return this.$swal({
        title: 'Confirm Delete',
        icon: 'info',
        text: 'Are you sure you want to delete this walking customer rate card?',
        showCancelButton: true,
        confirmButtonText: 'Yes, confirm!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        backdrop: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.onClickConfirm(id)
        }
      })
    },
    async onClickConfirm(id) {
      try {
        const res = await rateCardRepository.deleteWalkingCustomerRateCard(id)
        if (res.status === 200) {
          this.onClickRefresh()
          this.showSuccessMessage('Walking Customer Rate Card Deleted Successfully')
        } else {
          this.showErrorMessage("Couldn't Delete Walking Customer Rate Card")
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../@core/scss/vue/libs/vue-select.scss';
.dropdown .dropdown-menu {
  overflow-y: auto;
}
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
